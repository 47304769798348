.calendar-custom {
  display: block;
  .react-calendar {
    border: none;
    width: 100%;
  }
  .react-calendar__navigation__prev-button {
    img {
      margin-left: 10px;
    }
  }
  button:disabled img {
    filter: grayscale(100%);
    opacity: 0.5;
  }
  .react-calendar__month-view__days {
    gap: 1px;
    button {
      font-size: 14px !important;
      flex-basis: calc(14.2857% - 1px) !important;
    }
  }
}
