@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter+Moments&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://unpkg.com/browse/antd@5.0.0/dist/reset.css");

:root {
  --primary-color: #ff7731;
  --secondary-color: #faa935;
  --heading-color: #0b2727;
  --text-color: #6e7074;
  --font-name: "Inter", sans-serif !important;
  --subtitle-font-name: "Montserrat", cursive;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: 500;
  font-family: "Inter", sans-serif !important;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
}

a {
  text-decoration: none;
  color: unset;
}

ul {
  list-style: none;
}

b {
  font-weight: bold;
}

// .select-add-radio .ant-radio {
//   margin-bottom: 20px;
// }

.address-input {
  .ant-input {
    font-size: 15px;
    font-weight: 600;
    padding-left: 4px !important;
  }
}
.modal-view-map {
  .ant-modal-content {
    padding: 12px !important;
  }
}
.slider-container {
  .slick-next {
    right: 10px !important;
    z-index: 10;
  }
  .slick-prev {
    left: 8px !important;
    z-index: 10;
  }

  .slider-main img {
    height: 320px; /* Chiều cao cố định */
    object-fit: cover;
  }

  .slider-thumbnails img {
    height: 80px; /* Chiều cao nhỏ hơn cho thumbnails */
    object-fit: cover; /* Đảm bảo hình ảnh không bị méo */
    border-radius: 5px; /* Bo góc cho đẹp */
    transition: transform 0.3s ease; /* Hiệu ứng khi hover */
  }

  .slick-slide {
    padding: 5px; /* Khoảng cách giữa các thumbnails */
  }
}

.calendar-custom {
  display: block;
  .react-calendar {
    border: none;
    width: 100%;
  }
  .react-calendar__navigation__prev-button {
    img {
      margin-left: 10px;
    }
  }
  button:disabled img {
    filter: grayscale(100%);
    opacity: 0.5;
  }
}

.ant-picker-no-left {
  left: 0px !important;
  .ant-picker-time-panel {
    .ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected {
      .ant-picker-time-panel-cell-inner {
        background-color: #1677ff !important;
        color: white;
      }
    }
  }
}

.ant-slider {
  .ant-slider-mark {
    .ant-slider-mark-text:first-child {
      left: 0% !important;
    }
    .ant-slider-mark-text:last-child {
      left: 95% !important;
    }
  }
}

.ant-segmented-group {
  .ant-segmented-item-label {
    color: #22313f;
  }
  .ant-segmented-item-selected {
    background-color: #0064d2;
    .ant-segmented-item-label {
      color: white;
    }
  }
}

.slick-prev:before,
.slick-next:before {
  content: ""; /* Remove the default arrow */
}

.ant-carousel {
  .slick-prev {
    width: 24px !important;
    height: 24px !important;
    opacity: 1 !important;
  }

  .slick-next {
    width: 24px !important;
    height: 24px !important;
    opacity: 1 !important;
  }
}

.drawer-no-padding {
  background-color: #f9f9f9 !important;
  .ant-drawer-body {
    padding: 0px;
    background-color: #f9f9f9 !important;
  }
}

.drawer-no-header {
  .ant-drawer-header {
    display: none;
  }
  .ant-drawer-body {
    padding: 8px;
  }
}
.ant-drawer {
  .ant-drawer-header {
    background-color: #0064d2;
    padding: 12px 16px;
    .ant-drawer-header-title {
      color: white;
      text-transform: uppercase;
      position: relative;
      .ant-drawer-close {
        position: absolute;
        color: #0064d2;
        background-color: white;
        right: 0;
      }
    }
  }
}
.ant-pagination {
  .ant-pagination-item-ellipsis {
    color: white !important;
  }
  li {
    background-color: #0064d2 !important;
    a {
      color: white !important;
    }
  }
  .ant-pagination-item-active {
    background-color: white !important;
    a {
      color: #0064d2 !important;
    }
  }
  .ant-pagination-item-container {
    span {
      color: white;
    }
  }
  .ant-pagination-next,
  .ant-pagination-prev {
    button {
      background-color: #0064d2 !important;
      span {
        color: white !important;
      }
    }
  }
}
.ant-picker-dropdown {
  .ant-picker-time-panel {
    .ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected {
      .ant-picker-time-panel-cell-inner {
        background-color: #1677ff !important;
        color: white;
      }
    }
  }
}
.ant-progress {
  .ant-progress-outer {
    height: 100%;
    .ant-progress-inner {
      height: 100%;
      .ant-progress-bg {
        height: 100% !important;
      }
    }
  }
}
.loadmore-rating-list {
  min-height: 350px;
}
.custom-select {
  // .ant-select-arrow {
  //   display: none;
  // }
  .ant-select-selection-search input {
    // padding-left: 16px !important;
    font-weight: 600 !important;
  }
  .ant-select-selection-search-input::placeholder {
    font-weight: 600 !important;
  }
  .ant-select-selection-item {
    font-weight: 600 !important;
  }
  // .ant-select-selector {
  //   padding-left: 30px !important;
  // }
}
/* Tùy chỉnh thanh cuộn của Select */
.custom-scroll {
  &::-webkit-scrollbar {
    width: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background: transparent !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e2e2e2 !important;
    border-radius: 8px !important;
    border: 1px solid transparent !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #e2e2e2 !important;
  }
}
.ant-modal-root {
  .ant-modal-content {
    .ant-modal-header {
      .ant-modal-title {
        color: #0064d2 !important;
        font-size: 20px !important;
      }
    }
  }
}
.checkbox_booked .ant-checkbox-inner {
  background-color: rgb(207, 207, 207) !important;
  border-color: rgb(207, 207, 207) !important;
}
.checkbox_selecting .ant-checkbox-inner {
  background-color: rgb(186, 223, 65) !important;
  border-color: rgb(186, 223, 65) !important;
}

.tabs-no-bottom {
  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }
}
.ant-tabs {
  .ant-tabs-nav-list {
    display: flex;
    width: 100%;
  }
  .ant-tabs-tab {
    flex: 1;
    justify-content: center;
    text-align: center;

    .ant-tabs-tab-btn {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.booking {
  .border-error {
    border: 1px solid #ff4d4f !important;
  }

  .ant-select {
    border-color: #e6e8e9;
    border-radius: 8px;
    position: relative;
    .ant-select-selector {
      padding-left: 40px;
      background-color: #f5f6f6 !important;
      .ant-select-selection-search {
        input {
          padding-left: 30px;
        }
      }
      .ant-select-selection-item {
        padding-right: 0px;
        font-size: 14px;
      }
    }
    .ant-select-arrow {
      height: 100%;
      position: absolute;
      left: 10px; /* Adjust as needed */
      top: 6px;
      pointer-events: none;
    }
  }

  .ant-switch.ant-switch-checked {
    background-color: white !important;
    border: 2px solid #0064d2;
    .ant-switch-handle {
      inset-inline-start: calc(100% - 18px);
      top: 0px;
    }
    .ant-switch-handle::before {
      background-color: #0064d2;
    }
  }

  .ant-picker {
    background-color: #f5f6f6;
    border-radius: 8px;
    .ant-picker-input {
      input {
        height: 25px !important;
      }
    }
    .ant-picker-suffix {
      height: 100%;
      position: absolute;
      left: "-5px" !important; /* Adjust as needed */
      top: 0;
      pointer-events: none;
      margin: 0 !important;
      padding: 0 !important;
      img {
        width: 14px;
      }
    }
    .ant-picker-input {
      input {
        font-size: 15px;
        color: #22313f;
        padding-left: 22px;
        font-weight: bold;
      }
    }
  }
}

.ant-descriptions {
  .ant-descriptions-header {
    .ant-descriptions-title {
      font-size: 20px !important;
      color: #0064d2 !important;
    }
  }
  .ant-descriptions-view {
    tr th,
    tr td {
      padding: 12px !important;
    }
  }
}

.ant-picker-panel {
  &:last-child {
    width: 0;

    .ant-picker-header {
      position: absolute;
      right: 0;

      .ant-picker-header-prev-btn,
      .ant-picker-header-view {
        visibility: hidden;
      }
    }

    .ant-picker-body {
      display: none;
    }

    @media (min-width: 768px) {
      width: 280px !important;

      .ant-picker-header {
        position: relative;

        .ant-picker-header-prev-btn,
        .ant-picker-header-view {
          visibility: initial;
        }
      }

      .ant-picker-body {
        display: block;
      }
    }
  }
}

.calender-active {
  border: 1px solid #d72f20;
}
